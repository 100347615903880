import ForgotPasswordLayout from "../components/Layouts/AccountLayout/ForgotPasswordLayout";
import LoginLayout from "../components/Layouts/AccountLayout/LoginLayout";
import ResendEmailLayout from "../components/Layouts/AccountLayout/ResendEmailLayout";
import SignLayout from "../components/Layouts/AccountLayout/SignLayout";
import VerifyEmailLayout from "../components/Layouts/AccountLayout/VerifyEmailLayout";
import MobileAppAdmin from "../components/Layouts/AdminLayout/MobileApp";
import CategoryAdmin from "../components/Layouts/AdminLayout/CategoryAdmin";
import StatusAppAdmin from "../components/Layouts/AdminLayout/StatusAppAdmin";
import RoleAdmin from "../components/Layouts/AdminLayout/RoleAdmin";
import CustomerAdmin from "../components/Layouts/AdminLayout/CustomerAdmin";
import OTPAdmin from "../components/Layouts/AdminLayout/OTPAdmin";
import AdminPage from "../pages/AdminPage";
import AppPage from "../pages/AppPage";
import LoginPage from "../pages/LoginPage";
import UserPage from "../pages/UserPage";
import UserInfo from "../components/Layouts/UserLayout/UserInfo";
import ErrorPage from "../pages/ErrorPage";
import TaskLinkAdmin from "../components/Layouts/AdminLayout/TaskLinkAdmin";
import TaskHistoryAdmin from "../components/Layouts/AdminLayout/TaskHistoryAdmin";
import CodeLinkAdmin from "../components/Layouts/AdminLayout/CodeLinkAdmin";
import XuHistoryAdmin from "../components/Layouts/AdminLayout/XuHistoryAdmin";
import FarmPage from "../pages/FarmPage/index";
import TaskLinkPage from "../pages/TaskLinkPage";
import HistoryXu from "../components/Layouts/UserLayout/HistoryXu";
import PayHistory from "../components/Layouts/UserLayout/PayHistory";
import PayInfo from "../components/Layouts/UserLayout/PayInfo";
import Withdraw from "../components/Layouts/UserLayout/Withdraw";
import Logout from "../components/Layouts/AccountLayout/Logout";
import ChatPage from "../pages/ChatPage";
import OrderPage from "../pages/OrderPage";
import OrderHistoryAdmin from "../components/Layouts/AdminLayout/OrderHistoryAdmin";
import ReferenceFriend from "../components/Layouts/UserLayout/ReferenceFriend";
import FarmHistoryAdmin from "../components/Layouts/AdminLayout/FarmHistoryAdmin";
import DeviceUserAdmin from "../components/Layouts/AdminLayout/DeviceUserAdmin";
import FarmSeedAdmin from "../components/Layouts/AdminLayout/FarmSeedAdmin";
import LuckyWheelPage from "../pages/LuckyWheelPage";
import EventPage from "../pages/EventPage";

const publicRoutes = [
  { path: "/", component: AppPage },
  { path: "/event", component: EventPage },
  { path: "/task-link", component: TaskLinkPage },
  { path: "/account/login", component: LoginPage, layout: LoginLayout },
  { path: "/account/signup", component: LoginPage, layout: SignLayout },
  { path: "/account/logout", component: Logout },
  { path: "/404", component: ErrorPage },
  {
    path: "/account/lost-password",
    component: LoginPage,
    layout: ForgotPasswordLayout,
  },
  {
    path: "/account/verify/email/:email/:token",
    component: LoginPage,
    layout: VerifyEmailLayout,
  },
  {
    path: "/account/resend-email",
    component: LoginPage,
    layout: ResendEmailLayout,
  },
];

const privateRoutes = [
  {
    path: "/admin/mobile-app",
    component: AdminPage,
    layout: MobileAppAdmin,
    active_id: "ADMIN_MOBILE_APP",
  },
  {
    path: "/admin/category",
    component: AdminPage,
    layout: CategoryAdmin,
    active_id: "ADMIN_CATEGORY",
  },
  {
    path: "/admin/status-app",
    component: AdminPage,
    layout: StatusAppAdmin,
    active_id: "ADMIN_STATUS_APP",
  },
  {
    path: "/admin/customer",
    component: AdminPage,
    layout: CustomerAdmin,
    active_id: "ADMIN_CUSTOMER",
  },
  {
    path: "/admin/role",
    component: AdminPage,
    layout: RoleAdmin,
    active_id: "ADMIN_ROLE",
  },
  {
    path: "/admin/otp",
    component: AdminPage,
    layout: OTPAdmin,
    active_id: "ADMIN_OTP",
  },

  {
    path: "/admin/task-link",
    component: AdminPage,
    layout: TaskLinkAdmin,
    active_id: "ADMIN_TASKLINK",
  },

  {
    path: "/admin/task-history",
    component: AdminPage,
    layout: TaskHistoryAdmin,
    active_id: "ADMIN_TASKHISTORY",
  },

  {
    path: "/admin/code-link",
    component: AdminPage,
    layout: CodeLinkAdmin,
    active_id: "ADMIN_CODELINK",
  },

  {
    path: "/admin/history",
    component: AdminPage,
    layout: XuHistoryAdmin,
    active_id: "ADMIN_XUHISTORY",
  },

  {
    path: "/admin/farm-history",
    component: AdminPage,
    layout: FarmHistoryAdmin,
    active_id: "ADMIN_FARMHISTORY",
  },

  {
    path: "/admin/device-user",
    component: AdminPage,
    layout: DeviceUserAdmin,
    active_id: "ADMIN_DEVICE_USER",
  },
  {
    path: "/admin/farm-seed",
    component: AdminPage,
    layout: FarmSeedAdmin,
    active_id: "ADMIN_FARM_SEED",
  },

  {
    path: "/admin/order-history",
    component: AdminPage,
    layout: OrderHistoryAdmin,
    active_id: "ADMIN_ORDER_HISTORY",
  },

  {
    path: "/account/info",
    component: UserPage,
    layout: UserInfo,
    active_id: 1,
  },
  {
    path: "/account/history",
    component: UserPage,
    layout: HistoryXu,
    active_id: 2,
  },

  {
    path: "/account/pay-history",
    component: UserPage,
    layout: PayHistory,
    active_id: 3,
  },

  {
    path: "/account/pay-info",
    component: UserPage,
    layout: PayInfo,
    active_id: 4,
  },
  {
    path: "/account/withdraw",
    component: UserPage,
    layout: Withdraw,
    active_id: 5,
  },
  {
    path: "/account/invite",
    component: UserPage,
    layout: ReferenceFriend,
    active_id: 6,
  },

  {
    path: "/farm",
    component: FarmPage,
  },

  {
    path: "/chat",
    component: ChatPage,
  },
  {
    path: "/lucky-wheel",
    component: LuckyWheelPage,
  },

  {
    path: "/order-check",
    component: OrderPage,
  },
];

export { publicRoutes, privateRoutes };
